<template>
  <div class="push-md-4">
    <vCard :col="4" header="404 - Pagina niet gevonden">
      <h1>404!</h1>
    </vCard>
  </div>
</template>

<script>

export default {
  name: '404',
}
</script>